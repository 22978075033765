<template>
	<div>
		<div class="simple-title">Verify Your Details<span class="fal fa-eye"></span></div>
		<div class="form-wrapper page-has-nav small">
			<div class="form-container">
				<div class="text">
					<p>Please verify the details of your account below to finish signing up. If anything looks wrong, please correct it here.</p><br>
				</div>

				<form @submit.prevent="onSubmit">
					<form-errors :errors="errors"></form-errors>

					<field-errors v-if="submitAttempted" :form="$v.form" field="email"></field-errors>
					<div class="input-wrapper">
						<div class="input-title">Account E-mail</div>
						<div class="input-box"><input type="text" v-model="form.email"></div>
					</div>

					<submit-button text="Save" icon="fa-key" :loading="formSaving"></submit-button>
				</form>
			</div>
		</div>
	</div>
</template>

<script>

import FormMixin from '@/mixins/FormMixin';
import { required, email } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

export default {

	name: 'BusinessSignupStepTwoPage',

	mixins: [ FormMixin ],

	data() {
		return {
			form: {
				email: null,
				stepTwo: true,
			},
		};
	},

	computed: mapGetters([ 'currentUser' ]),

	validations: {
		form: {
			email: {
				required,
				email,
			},
		}
	},

	mounted() {
		this.form = { ...this.currentUser };
	},

	methods: {

		async onSubmit() {
			this.submit(async () => {
				await this.$api.post('/business', this.form);
				await this.$store.dispatch('getAccounts', true);

				this.$nextTick(() => {
					if (this.$route.query.new) {
						this.$router.push('/business/welcome');
					} else {
						this.$store.dispatch('goIntended', '/business/locations');
					}
				});
			}, () => {
				this.$store.dispatch('errorToast', 'Unable to update your information. Please try again.');
			});


		}
	}
};

</script>
